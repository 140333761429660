import React from "react";
import s from "./style.module.css";
import { useState, useEffect } from "react";

import photoProjet from "assets/images/bentoProjets/Grid-portfolio.png";
import photoProjet1 from "assets/images/bentoProjets/Grid-portfolio (1).png";
import photoProjet2 from "assets/images/bentoProjets/Grid-portfolio (2).png";
import photoProjet3 from "assets/images/bentoProjets/Grid-portfolio (3).png";
import photoProjet4 from "assets/images/bentoProjets/Grid-portfolio (4).png";
import photoProjet5 from "assets/images/bentoProjets/Grid-portfolio (5).png";
import photoProjet6 from "assets/images/bentoProjets/Grid-portfolio (6).png";
import photoProjet7 from "assets/images/bentoProjets/Grid-portfolio (7).png";
import photoProjet8 from "assets/images/bentoProjets/Grid-portfolio (8).png";
import photoProjet9 from "assets/images/bentoProjets/Grid-portfolio (9).png";
import photoProjet10 from "assets/images/bentoProjets/Grid-portfolio (10).png";
import photoProjet11 from "assets/images/bentoProjets/Grid-portfolio (11).png";


// Import des images miniatures
import projet0 from "assets/images/bentoProjets2/Grid-portfolio (0).png";
import projet1 from "assets/images/bentoProjets2/Grid-portfolio (1).png";
import projet2 from "assets/images/bentoProjets2/Grid-portfolio (2).png";
import projet3 from "assets/images/bentoProjets2/Grid-portfolio (3).png";
import projet4 from "assets/images/bentoProjets2/Grid-portfolio (4).png";
import projet5 from "assets/images/bentoProjets2/Grid-portfolio (5).png";
import projet6 from "assets/images/bentoProjets2/Grid-portfolio (6).png";
import projet7 from "assets/images/bentoProjets2/Grid-portfolio (7).png";
import projet8 from "assets/images/bentoProjets2/Grid-portfolio (8).png";
import projet9 from "assets/images/bentoProjets2/Grid-portfolio (9).png";

// Import de l'image grand format
import projetG0 from "assets/images/bentoProjets2/portfolio (0).png"; 
import projetG1 from "assets/images/bentoProjets2/portfolio (1).png"; 
import projetG2 from "assets/images/bentoProjets2/portfolio (2).png"; 
import projetG3 from "assets/images/bentoProjets2/portfolio (3).png"; 
import projetG4 from "assets/images/bentoProjets2/portfolio (4).png"; 
import projetG5 from "assets/images/bentoProjets2/portfolio (5).png"; 
import projetG6 from "assets/images/bentoProjets2/portfolio (6).png"; 
import projetG7 from "assets/images/bentoProjets2/portfolio (7).png"; 
import projetG8 from "assets/images/bentoProjets2/portfolio (8).png"; 
import projetG9 from "assets/images/bentoProjets2/portfolio (9).png"; 

export function BentoProjets() {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  // Fonction pour gérer l'affichage en plein écran
  const handleImageClick = (thumbnail) => {
    const fullscreenMapping = {
      [projet0]: projetG0,
      [projet1]: projetG1,
      [projet2]: projetG2,
      [projet3]: projetG3,
      [projet4]: projetG4,
      [projet5]: projetG5,
      [projet6]: projetG6,
      [projet7]: projetG7,
      [projet8]: projetG8,
      [projet9]: projetG9,
    };

    if (fullscreenImage === thumbnail || fullscreenImage === fullscreenMapping[thumbnail]) {
      setFullscreenImage(null); // Quitter le plein écran
    } else {
      setFullscreenImage(fullscreenMapping[thumbnail] || thumbnail); // Afficher l'image en plein écran
    }
  };

  // Désactiver le scrolling de la page principale en plein écran
  useEffect(() => {
    if (fullscreenImage) {
      document.body.classList.add(s.noScroll); // Ajout de la classe pour bloquer le scroll
    } else {
      document.body.classList.remove(s.noScroll); // Retrait de la classe pour rétablir le scroll
    }
  }, [fullscreenImage]);

  return (
    <div>
      <div className={s.projets}>
        <div><img src={projet0} alt="" className={s.img} onClick={() => handleImageClick(projet0)} /></div>
        <div><img src={projet1} alt="" className={s.img} onClick={() => handleImageClick(projet1)} /></div>
        <div><img src={projet2} alt="" className={s.img} onClick={() => handleImageClick(projet2)} /></div>
        <div><img src={projet3} alt="" className={s.img} onClick={() => handleImageClick(projet3)} /></div>
        <div><img src={projet4} alt="" className={s.img} onClick={() => handleImageClick(projet4)} /></div>
        <div><img src={projet5} alt="" className={s.img} onClick={() => handleImageClick(projet5)} /></div>
        <div><img src={projet6} alt="" className={s.img} onClick={() => handleImageClick(projet6)} /></div>
        <div><img src={projet7} alt="" className={s.img} onClick={() => handleImageClick(projet7)} /></div>
        <div><img src={projet8} alt="" className={s.img} onClick={() => handleImageClick(projet8)} /></div>
        <div><img src={projet9} alt="" className={s.img} onClick={() => handleImageClick(projet9)} /></div>
      </div>

      {fullscreenImage && (
        <div className={s.fullscreen} onClick={() => setFullscreenImage(null)}>
          <img src={fullscreenImage} alt="" className={s.fullscreenImg} />
        </div>
      )}
    </div>

      /*<div className={s.bento}>

      <div className={s.partieSup}>
        <div className={s.partieSup2}>
          <div className={s.horizontal}>
            <div className={s.test}>
            <div className={s.horizontal}>
              <div className={s.boxProjet}>
                <img className={s.photo} src={photoProjet} alt="Projet 0" />
              </div>
              <div className={s.boxProjet1}>
                <img className={s.photo} src={photoProjet1} alt="Projet 1" />
              </div>
            </div>
            <div className={s.horizontal}>
            <div className={s.boxProjet4}>
                <img className={s.photo} src={photoProjet4} alt="Projet 4" />
              </div>
              <div className={s.boxProjet3}>
                <img className={s.photo} src={photoProjet3} alt="Projet 3" />
              </div>

            </div>
            </div>
            <div className={s.boxProjet2}>
              <img className={s.photo} src={photoProjet2} alt="Projet 2" />
            </div>
       
          </div>
        </div>
        <div className={s.partieSup1}>
        <div className={s.boxProjet11}>
          <img className={s.photoProjet11} src={photoProjet11} alt="Projet 0" />
        </div>
        </div>
      </div>



      <div className={s.horizontal}>
        <div className={s.boxProjet6}>
          <img className={s.photoProjet6} src={photoProjet6} alt="Projet 6" />
        </div>
        <div className={s.boxProjet5}>
          <img className={s.photoProjet5} src={photoProjet5} alt="Projet 5" />
        </div>
      </div>

      <div className={s.horizontal}>
        <div className={s.boxProjet10}>
          <img className={s.photoProjet10} src={photoProjet10} alt="Projet 10" />
        </div>
        <div className={s.vertical}>
          <div className={s.horizontal}>
            <div className={s.boxProjet8}>
              <img className={s.photoProjet8} src={photoProjet8} alt="Projet 8" />
            </div>
            <div className={s.boxProjet7}>
              <img className={s.photoProjet7} src={photoProjet7} alt="Projet 7" />
            </div>
          </div>
          <div className={s.boxProjet9}>
            <img className={s.photoProjet9} src={photoProjet9} alt="Projet 9" />
          </div>
        </div>
      </div>
    </div>*/
  );
}
