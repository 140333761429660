import React from 'react';
import { useLocation } from 'react-router-dom';
import s from "./style.module.css";

export function BoutonBanniere({ children, onClick }) {
  const location = useLocation();

  // Déterminer la classe de fond en fonction de la page active
  const getButtonClass = () => {
    switch (location.pathname) {
      case '/NotreCollectif':
        return `${s.button} ${s.buttonCollectif}`;
      case '/NosProjets':
        return `${s.button} ${s.buttonProjets}`;
      case '/NosOffres':
        return `${s.button} ${s.buttonOffres}`;
      default:
        return s.button;
    }
  };

  return (
    <button
      type="button"
      className={getButtonClass()}
      onClick={onClick} // Le clic est toujours géré ici, mais sans gestion d'état
    >
      {children}
    </button>
  );
}