import s from "./style.module.css"
import { Vague } from "components/HautDePage/Vague/Vague";
import VagueViolette from "assets/images/vague_violette.svg";

export function MentionsLegales() {
return (<div>
      <div className={s.vague}>
        <Vague image={VagueViolette} />
      </div>
      <div className={s.mentionsContainer}>
      <div className={s.titre}>
          Mentions <span className={s.shrikhand}>légales</span>{" "}
        </div>
        <div className={s.section}>
        <h2>1. Informations légales</h2>
        <p>
          Le site <strong>pixeltrust.fr</strong> est édité par :<br />
          Un collectif d’entrepreneurs composé de : Louise Delory (micro-entrepreneure), Corentin Luchart (SARL), Thibaut Saudemont.
        </p>
        <p>
          Directeur de la publication : [Nom du directeur de la publication]<br />
          Hébergeur du site :<br />
          Nom de l'hébergeur : o2switch<br />
          Siret : 510 909 807 00032<br />
          RCS Clermont Ferrand<br />
          SAS au capital de 100 000€<br />
          Adresse de l'hébergeur : Chem. des Pardiaux, 63000 Clermont-Ferrand<br />
          Téléphone : 04 44 44 60 40<br />
          Site web : <a href="https://www.o2switch.fr" target="_blank" rel="noopener noreferrer">o2switch.fr</a>
        </p>
      </div>

      <div className={s.section}>
        <h2>2. Propriété intellectuelle</h2>
        <p>
          Le site <strong>pixeltrust.fr</strong> et l'ensemble de son contenu (textes, images, graphismes, logos, etc.) sont la propriété exclusive des membres du collectif ou de ses partenaires et sont protégés par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, représentation, modification ou adaptation de tout ou partie du contenu est interdite sans l'autorisation préalable écrite de PixelTrust.
        </p>
      </div>

      <div className={s.section}>
        <h2>3. Données personnelles</h2>
        <p>
          Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés en France, vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition concernant vos données personnelles. Vous pouvez exercer ce droit en nous contactant à l'adresse suivante : <a href="mailto:mesdonnees@pixeltrust.fr">mesdonnees@pixeltrust.fr</a>.
        </p>
      </div>

      <div className={s.section}>
        <h2>4. Cookies</h2>
        <p>
          Le site <strong>pixeltrust.fr</strong> utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez configurer votre navigateur pour refuser les cookies. Toutefois, cela pourrait affecter votre accès à certaines fonctionnalités du site.
        </p>
      </div>

      <div className={s.section}>
        <h2>5. Responsabilité</h2>
        <p>
          PixelTrust décline toute responsabilité en cas de dommages directs ou indirects résultant de l'utilisation du site, notamment en cas de virus ou de pertes de données. Le site peut contenir des liens vers des sites tiers. Nous n'avons aucun contrôle sur ces sites et déclinons toute responsabilité concernant leur contenu.
        </p>
      </div>

      <div className={s.section}>
        <h2>6. Contact</h2>
        <p>
          Pour toute question relative aux mentions légales, veuillez nous contacter à :<br />
          Adresse e-mail : <a href="mailto:contact@pixeltrust.fr">contact@pixeltrust.fr</a>
        </p>
      </div>
      </div>
</div>)

}