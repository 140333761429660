
import s from "./style.module.css";


export function Vague({image}) {


  return (
    <div className={s.container}>
      <img className={s.vague} src={image} alt='vague' />
    </div>
  );
}
