import React from "react";
import s from "./style.module.css";
import { BentoProjets } from "components/CorpsDePage/BentoProjets/BentoProjets";
import { BoutonBanniere } from "components/HautDePage/BoutonBanniere/boutonBanniere";
import { GrosTitre } from "components/HautDePage/GrosTitre/GrosTitre";
import { Vague } from "components/HautDePage/Vague/Vague";
import VagueJaune from "assets/images/vague_jaune.svg";
import { Fleche } from "components/HautDePage/Fleche/Fleche";
import { Formulaire } from "components/CorpsDePage/Formulaire/Formulaire";
import { useRef } from "react";

export function NosProjets() {
  const formulaireRef = useRef(null); // Création de la référence pour le formulaire
  const ProjetsRef = useRef(null);


  const scrollToProject = () => {
    if (ProjetsRef.current) {
      ProjetsRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };







  return (
    <div className={s.container}>
      {/* HAUTE DE PAGE */}
      <div className={s.partieColore}>
        <div className={s.grosTitre}>
        <GrosTitre/>
        </div>
        <div>
          <div className={s.content}>
            <div className={s.PresentationEtBouton}>
              <div className={s.presentation}>

                Avant la naissance du collectif, nous avions déjà réalisé quelques projets.
              </div>
              <BoutonBanniere onClick={scrollToProject}>Montrez-moi tout !</BoutonBanniere>
            </div>

          </div>
          <div className={s.fleche}>
            <Fleche />
          </div>
        </div>
      </div>
      <div className={s.vague}>
        <Vague image={VagueJaune} />
      </div>

      {/* BENTO */}
      <div className={s.bento}>
        <div ref={ProjetsRef} className={s.titreBento}>
          Nos belles <span className={s.shrikhand}>idées</span>{" "}
        </div>
        <div>
        <BentoProjets/>
      </div>
      </div>

      {/* FORMULAIRE */}
      <div className={s.formulaireSection}>
      <Formulaire  titre="Vous avez un projet ?" />
      </div>
    </div>
  );
}
