import s from "./style.module.css"


export function Logo({image}) {
return (
<div className="s.container">
    <img className={s.image} src={image}  alt="logo" />
</div>
)

}