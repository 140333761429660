import React from 'react';
import s from "./style.module.css";
import fleche from "assets/images/fleche.png";
import multiFleches from "assets/images/multiFleche.png";
import { useLocation } from 'react-router-dom';

export function Fleche() {
  const location = useLocation();

  return (
    <div className={s.container}>
      {location.pathname === '/NosOffres' ? (
        <img className={s.MultiFleche} src={multiFleches} alt='multi-fleches' />
      ) : (
        <img className={s.fleche} src={fleche} alt='fleche' />
      )}
    </div>
  );
}