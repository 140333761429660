import React, { useState, useEffect, useRef } from 'react';
import s from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";

export function Burger() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 576) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <div className={s.burgerContainer} ref={menuRef}>
      <div className={`${s.BoutonBurger} ${isOpen ? s.open : ''}`} onClick={handleClick}>
        <div className={s.bar}></div>
        <div className={s.bar}></div>
        <div className={s.bar}></div>
      </div>
      {isOpen && (
        <div className={s.dropdownMenu}>
          <div className={s.menuItem}><Link to="/" onClick={handleLinkClick}>Pixel<span className={s.shrikhand}>Trust</span></Link></div>
          <div className={s.separator}></div>
          <div className={s.menuItem}><Link to="/NotreCollectif" onClick={handleLinkClick}>Notre collectif</Link></div>
          <div className={s.separator}></div>
          <div className={s.menuItem}><Link to="/NosProjets" onClick={handleLinkClick}>Nos projets</Link></div>
          <div className={s.separator}></div>
          <div className={s.menuItem}><Link to="/NosOffres" onClick={handleLinkClick}>Nos offres</Link></div>
          <div className={s.separator}></div>
          <button className={s.contactButton} onClick={handleLinkClick}>
            <Link to="/Contact">Contact</Link>
          </button>
        </div>
      )}
    </div>
  );
}
