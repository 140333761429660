import React from 'react';
import { useLocation } from 'react-router-dom';
import s from "./style.module.css";

export function BoutonContact({ children, onClick }) {
  const location = useLocation();

  // Déterminer la classe de fond en fonction de la page active
  const getButtonClass = () => {
    if (location.pathname === '/NosOffres') {
      return s.buttonContact;
    }
    return s.button;
  };

  return (
    <button onClick={onClick} type="button" className={`btn ${getButtonClass()}`}>
      {children}
    </button>
  );
}
