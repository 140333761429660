import React, { useState } from 'react';
import s from "./style.module.css";
import fleche from "assets/images/flecheFooter.png"


export function FlecheFooter() {


  return (
    <div className={s.container}>
      <img className={s.fleche} src={fleche} alt='fleche' />

    </div>
  );
}
