import s from "./style.module.css";
import { useNavigate, useLocation } from "react-router-dom";

export function GrosTitre() {
  const location = useLocation();



  return (
    <div className={s.container }>
      <div className={location.pathname === "/" ? s.superieur1 : s.none}>
        Le collectif <div className={location.pathname === "/" ? s.shrikhand : ""}>qui en jette</div>
      </div>
      <div className={location.pathname === "/NotreCollectif" ? s.superieur1 : s.none}>
        Sérieusement <div className={location.pathname === "/NotreCollectif" ? s.shrikhand2 : ""}>à la cool</div>
      </div>
      <div className={location.pathname === "/Contact" ? s.superieur1 : s.none}>
        Sérieusement <div className={location.pathname === "/Contact" ? s.shrikhand2 : ""}>à la cool</div>
      </div>
      <div className={location.pathname === "/NosProjets" ? s.superieur2 : s.none}>
        Des idées <div className={location.pathname === "/NosProjets" ? s.shrikhand3 : ""}>pour briller</div>
      </div>
      <div className={location.pathname === "/NosOffres" ? s.superieur1 : s.none}>
        Des pros pour<div className={location.pathname === "/NosOffres" ? s.shrikhand4 : ""}>vous aider</div>
      </div>
    </div>
  );
}

