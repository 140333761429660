import React from "react";
import s from "./style.module.css";
import { Formulaire } from "components/CorpsDePage/Formulaire/Formulaire";
import { BoutonBanniere } from "components/HautDePage/BoutonBanniere/boutonBanniere";
import { Vague } from "components/HautDePage/Vague/Vague";
import { GrosTitre } from "components/HautDePage/GrosTitre/GrosTitre";
import VagueRose from "assets/images/vague_rose.svg";
import { Fleche } from "components/HautDePage/Fleche/Fleche";
import { useRef } from "react";
import louise from "assets/images/louise.jpg";
import thibaut from "assets/images/thibaut.jpg";
import corentin from "assets/images/corentin.jpg";
import quentin from "assets/images/quentin.jpg";
import clap from "assets/images/clap.svg";


export function Contact() {
  const formulaireRef = useRef(null); // Création de la référence pour le formulaire

  const scrollToForm = () => {
    if (formulaireRef.current) {
      formulaireRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };
  return (
    <div className={s.container}>
      {/* HAUTE DE PAGE */}
      <div className={s.partieColore}>
        <div className={s.grosTitre}>
          <GrosTitre />
        </div>
        <div>
          <div className={s.content}>
            <div className={s.PresentationEtBouton}>
              <div className={s.presentation}>
                PixelTrust c'est avant tout une aventure
                <br /> entrepreneuriale entre potes. C'est le travail sérieux{" "}
                <br /> et passionné, avec une dose de fun
              </div>
              <div className={s.BoutonBanniere}>
                <BoutonBanniere onClick={scrollToForm}>
                  Euh, dites m'en plus...
                </BoutonBanniere>
              </div>
            </div>
          </div>
          <div className={s.fleche}>
            <Fleche />
          </div>
        </div>
      </div>
      <div className={s.vague}>
        <Vague image={VagueRose} />
      </div>

      <div className={s.titreCorps}>
            On se <span className={s.shrikhand}>contacte !</span>
          </div>

      <div className={s.profils}>
        <div className={s.card1}>
          <div className={s.prenom}>Louise</div>
          <div className={s.profil}>
            <div>
          <img src={louise} alt=""  className={s.photo}/>
            </div>
            <div className={s.informations}>
              <li className={s.information}>
                <span>Webmarketing & Webdesign</span>
              </li>
              <li className={s.information}>
                <span>Blonde@PixelTrust.com</span>
              </li>
              <li className={s.information}>
                <span>06 82 42 10 19</span>
              </li>
            </div>
          </div>
        </div>
        <div className={s.card2}>
          <div className={s.prenom}>Quentin</div>
          <div className={s.profil}>
            <div>
          <img src={quentin} alt=""  className={s.photo}/>
            </div>
            <div className={s.informations}>
              <li className={s.information}>
                <span>Developpement Web et Applications</span>
              </li>
              <li className={s.information}>
                <span>Blonde@PixelTrust.com</span>
              </li>
              <li className={s.information}>
                <span>06 82 42 10 19</span>
              </li>
            </div>
          </div>
        </div>
        <div className={s.card3}>
          <div className={s.prenom}>Corentin</div>
          <div className={s.profil}>
            <div>
          <img src={corentin} alt=""  className={s.photo}/>
            </div>
            <div className={s.informations}>
              <li className={s.information}>
                <span>Webmarketing / UI & Design Graphique</span>
              </li>
              <li className={s.information}>
                <span>Blonde@PixelTrust.com</span>
              </li>
              <li className={s.information}>
                <span>06 82 42 10 19</span>
              </li>
            </div>
          </div>
        </div>
        <div className={s.card4}>
          <div className={s.prenom}>Thibaut</div>
          <div className={s.profil}>
            <div>
          <img src={thibaut} alt=""  className={s.photo}/>
            </div>
            <div className={s.informations}>
              <li className={s.information}>
                <span>Developpement Web</span>
              </li>
              <li className={s.information}>
                <span>Blonde@PixelTrust.com</span>
              </li>
              <li className={s.information}>
                <span>06 82 42 10 19</span>
              </li>
            </div>
          </div>
        </div>

      </div>
      {/* FORMULAIRE */}
      <div ref={formulaireRef} className={s.formulaireSection}>
        <Formulaire titre="Vous avez un projet ?" />
      </div>
    </div>
  );
}
