import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { Provider } from "react-redux";
import { store } from "store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NosOffres } from "pages/NosOffres/NosOffres";
import { NosProjets } from "pages/NosProjets/NosProjets";
import { NotreCollectif } from "pages/NotreCollectif/NotreCollectif";
import { PagePrincipale } from "pages/PagePrincipale/PagePrincipale";
import { PageErreur } from "pages/PageErreur/PageErreur";
import { MentionsLegales } from "pages/MentionsLegales/MentionsLegales";
import { Contact } from "pages/Contact/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<PagePrincipale />}></Route>
            <Route path="/NosOffres" element={<NosOffres />}></Route>
            <Route path="/NosProjets" element={<NosProjets />}></Route>
            <Route path="/NotreCollectif" element={<NotreCollectif />}></Route>
            <Route path="/MentionsLegales" element={<MentionsLegales />}></Route>
            <Route path="/Contact" element={<Contact />}></Route>
          </Route>
          <Route path="/PageErreur" element={<PageErreur />}></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
