import { Outlet } from "react-router-dom";
import "./global.css";
import "./style.module.css";
import { Header } from "components/HautDePage/Header/Header";
import { Footer } from "components/BasDePage/Footer/Footer";

export function App() {
  return (
    <div className="">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}