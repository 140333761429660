import React from "react";
import s from "./style.module.css";
import { BoutonBanniere } from "components/HautDePage/BoutonBanniere/boutonBanniere";
import { Vague } from "components/HautDePage/Vague/Vague";
import { Fleche } from "components/HautDePage/Fleche/Fleche";
import { PhotoProfil } from "components/CorpsDePage/PhotoProfil/PhotoProfil";
import { Bento } from "components/CorpsDePage/Bento/Bento";
import { Formulaire } from "components/CorpsDePage/Formulaire/Formulaire";
import { GrosTitre } from "components/HautDePage/GrosTitre/GrosTitre";
import VagueViolette from "assets/images/vague_violette.svg";
import louise from "assets/images/louise.jpg";
import thibaut from "assets/images/thibaut.jpg";
import corentin from "assets/images/corentin.jpg";
import quentin from "assets/images/quentin.jpg";
import { useRef } from "react";
import flecheBlanche from "assets/images/flecheBlanche.png";



export function PagePrincipale() {
  const formulaireRef = useRef(null); // Création de la référence pour le formulaire
  const profilRef = useRef(null); // Création de la référence pour le formulaire

  const scrollToProfil = () => {
    if (profilRef.current) {
      profilRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };
  const scrollToForm = () => {
    if (formulaireRef.current) {
      formulaireRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };




  return (
    <div className={s.container}>
      {/* HAUTE DE PAGE */}
      <div className={s.partieColore}>
        <div className={s.grosTitre}>
          <GrosTitre />
        </div>
        <div>
          <div className={s.content}>
            <div className={s.presentationEtBouton}>
              <div className={s.presentation}>
                Chez PixelTrust, notre point fort c’est la proximité. La
                proximité entre nous, et avec vous !
              </div>
              <div className={s.boutonBanniere}>
              <BoutonBanniere onClick={scrollToProfil}>Qui sommes nous ?</BoutonBanniere>
              </div>
            </div>

          </div>
          <div className={s.fleche}>
            <Fleche />
          </div>
        </div>
      </div>
      <div className={s.vague}>
        <Vague image={VagueViolette} />
      </div>

      {/* PORTRAITS */}
      <div  ref={profilRef} className={s.partiePortraits}>
        <div className={s.titrePortraits}>
          PixelTrust,
          <br /> ce sont <div className={s.shrikhand}>des gens...</div>{" "}
        </div>
        <div className={s.présentationPortraits}>
          <div className={s.présentationPortraits1}>
            Notre volonté est de regrouper des profils et des sensibilités
            différentes.
          </div>
          <div>
            Déjà, parce que plus on est de fous (folles) plus on rit ! Et aussi,
            tout simplement pour pouvoir vous proposer des prestations aux
            petits oignons !
          </div>
        </div>
        <div className={`row ${s.portraits}`}>
          <div className="col-6 col-lg-3">
            <PhotoProfil
              photo={louise}
              prenom="Louise"
              job="Webmarketing Webdesign"
            />
          </div>
          <div className="col-6 col-lg-3">
            <PhotoProfil
              photo={corentin}
              prenom="Corentin"
              job="Webdesign / UI  Design Graphique"
            />
          </div>
          <div className="col-6 col-lg-3">
            <PhotoProfil
              photo={quentin}
              prenom="Quentin"
              job="Developpement web et applications"
            />
          </div>
          <div className="col-6 col-lg-3">
            <PhotoProfil
              photo={thibaut}
              prenom="Thibaut"
              job="Developpement web"
            />
          </div>
        </div>
      </div>

      {/* BENTO */}
      <div className={s.bentoSection}>
        <div className={s.bentoContent}>
          <div className={s.titrePortraits}>
            ... qui ont <div className={s.shrikhand}>du talent !</div>
          </div>
          <div className={s.bento}>
            <Bento />
          </div>
          <div className={s.ButtonContainer}>
            <button className={s.boutonContact} onClick={scrollToForm}> 
              Parlez-nous de vous  <img src={flecheBlanche} alt="fleche blanche" />
            </button>
          </div>
        </div>

      </div>

      {/* FORMULAIRE */}
      <div ref={formulaireRef} className={s.formulaireSection}> 
        <Formulaire titre="Vous avez un projet ?" />
      </div>
    </div>
  );
}
