import React from "react";
import s from "./style.module.css";

export function PhotoProfil({ photo, prenom, job }) {
  return (
    <div className={s.profil}>
      <div className={s.photoWrapper}>
        <div className={s.photoContainer}>
          <img className={s.photo} src={photo} alt="Photo de profil" />
        </div>
      </div>
      <div className={s.prenom}>
        <span className={s.prenomText}>{prenom}</span>
      </div>
      <div className={s.job}>{job}</div>
    </div>
  );
}
