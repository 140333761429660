import React from "react";
import s from "./style.module.css";
import { LogoFooter } from "../LogoFooter/LogoFooter";
import logo from "assets/images/logo2.svg";
import { FlecheFooter } from "../FlecheFooter/FlecheFooter";
import { Link } from "react-router-dom";

export function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={s.footer}>
      <div className={s.container}>
        <div className={s.logoContainer1}>
          <Link to="/" onClick={scrollToTop}>
            <LogoFooter image={logo} />
          </Link>
        </div>

        <div className={s.footerLists}>
          <div className={s.logoContainer2}>
            <Link to="/" onClick={scrollToTop}>
              <LogoFooter image={logo} />
            </Link>
          </div>
          <div className={s.footerList}>
            <div className={s.listItem}>Création site internet</div>
            <div className={s.listItem}>Développement</div>
            <div className={s.listItem}>Webmarketing</div>
            <div className={s.listItem}>Création graphique</div>
          </div>

          <div className={s.footerList}>
            <div className={s.listItem}>Contact</div>
            <div className={s.listItem}>
              <Link to="/MentionsLegales" className={s.link} onClick={scrollToTop}>
                Mentions légales
              </Link>
            </div>
          
          </div>

          <div className={s.fleche} onClick={scrollToTop}>
            <FlecheFooter />
          </div>
        </div>

        <div className={s.footerRights}>
          © PixelTrust 2024 - Tous droits réservés.
        </div>
      </div>
    </footer>
  );
}
