import React from "react";
import s from "./style.module.css";
import { BoutonBanniere } from "components/HautDePage/BoutonBanniere/boutonBanniere";
import { Vague } from "components/HautDePage/Vague/Vague";
import { GrosTitre } from "components/HautDePage/GrosTitre/GrosTitre";
import VagueRose from "assets/images/vague_rose.svg";
import { Fleche } from "components/HautDePage/Fleche/Fleche";
import { Formulaire } from "components/CorpsDePage/Formulaire/Formulaire";
import tempo from "assets/images/pizza.svg";
import clap from "assets/images/clap.svg";
import { useRef } from "react";
import flecheBlanche from "assets/images/flecheBlanche.png";
import { useNavigate, useLocation } from "react-router-dom";


export function NotreCollectif() {
  const formulaireRef = useRef(null); // Création de la référence pour le formulaire
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToForm = () => {
    if (formulaireRef.current) {
      formulaireRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };
  return (
    <div className={s.container}>
      {/* HAUTE DE PAGE */}
      <div className={s.partieColore}>
        <div className={s.grosTitre}>
          <GrosTitre />
        </div>
        <div>
          <div className={s.content}>
            <div className={s.PresentationEtBouton}>
              <div className={s.presentation}>
                PixelTrust c'est avant tout une aventure
                <br /> entrepreneuriale entre potes. C'est le travail sérieux{" "}
                <br /> et passionné, avec une dose de fun
              </div>
              <div className={s.BoutonBanniere}>
                <BoutonBanniere onClick={scrollToForm}>
                  Euh, dites m'en plus...
                </BoutonBanniere>
              </div>
            </div>
          </div>
          <div className={s.fleche}>
            <Fleche />
          </div>
        </div>
      </div>
      <div className={s.vague}>
        <Vague image={VagueRose} />
      </div>

      {/* POURQUOI */}

      <div className={s.titreCorps}>
        Pourquoi <span className={s.shrikhand}>PixelTrust ?</span>
      </div>
      <div className={s.pourquoiEnsembleContenaire}>
        <div className={s.pourquoiEnsembleText}>
          <div className={s.textSeparation}>
            PixelTrust, c’est une idée toute simple : unir les talents pour
            aller plus loin et vous laisser vous concentrer sur votre véritable
            expertise.
          </div>
          Avec PixelTrust, vous avez le combo gagnant :
          <li>
            Stratégie marketing : On élabore des plans infaillibles pour
            conquérir votre marché.
          </li>
          <li>
            Création graphique digitale : Webdesign et UX/UI qui en jettent.
          </li>
          <li>
            Création graphique globale : De la charte graphique aux enseignes en
            passant par les logos et supports imprimés.
          </li>
          <li className={s.textSeparation}>
            Développement : On transforme vos idées en réalité numérique.
          </li>
          <div>
            Toutes ces compétences réunies nous permettent de vous offrir une
            vision complète et un accompagnement global pour votre projet. En
            bref, on s’occupe de la partie technique, vous vous occupez de
            l’essentiel.
          </div>
        </div>
        <div className={s.imageContainer}>
          <img className={s.image} src={clap} alt="clap" />
        </div>
      </div>
      <div className={s.ButtonContainer} onClick={() => navigate("/NosProjets")}>
        <button className={s.boutonContact} onClick={scrollToForm}>
          Voir nos réalisations 
        </button>
      </div>

      <div className={s.titreCorps}>
        Ensemble, <span className={s.shrikhand}>on va plus loin !</span>
      </div>
      <div className={s.pourquoiEnsembleIntro}>
        Ensemble, nos idées s'éclatent et nos talents se mélangent, ouvrant des
        horizons de folie. La collab', c'est le top : ça booste nos parcours
        individuels et crée une énergie qui dépasse nos limites. Ensemble, on
        défonce les obstacles avec style et solidarité, atteignant des sommets
        qu'on n'aurait jamais imaginés en solo.
      </div>
      <div className={s.pourquoiEnsembleContenaire}>
        <div className={s.imageContainer}>
          <img className={s.image} src={tempo} alt="logo" />
        </div>
        <div className={s.pourquoiEnsembleText}>
          <div className={s.textSeparation}>
            Créer un site web avec un collectif d'amis, c'est comme organiser
            une soirée pizza entre pros ! Chacun apporte son talent : un
            graphiste pour la beauté, un développeur pour la magie, un UX/UI
            designer pour une interface qui claque.
          </div>
          <div>
            Le résultat ? Un site unique, à votre image, fait avec amour et fun.
          </div>
          <div>
            Envie de faire partie de l'aventure ? Contactez-nous et transformons
            votre présence en ligne ensemble ! 🍕💻🎨
          </div>
        </div>
      </div>
      <div className={s.ButtonContainer}>
        <button className={s.boutonContact} onClick={scrollToForm}>
          On en discute ? <img src={flecheBlanche} alt="fleche blanche" />
        </button>
      </div>
      {/* FORMULAIRE */}
      <div ref={formulaireRef} className={s.formulaireSection}>
        <Formulaire titre="Vous avez un projet ?" />
      </div>
    </div>
  );
}
