import React, { useState } from "react";
import emailjs from "emailjs-com";
import s from "./style.module.css";
import clap from "assets/images/clap.svg"; // Import the clap image

export function Formulaire({ titre }) {
  const [form, setForm] = useState({
    prenom: "",
    nom: "",
    email: "",
    entreprise: "",
    objet: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false); // State to handle form submission

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_ioaurhp";
    const templateId = "template_tiqyak2";
    const userId = "lCqCHZojD4tv_0wm_";

    emailjs.send(serviceId, templateId, form, userId).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setFormSubmitted(true); // Set the formSubmitted state to true
      },
      (error) => {
        console.log("FAILED...", error);
        alert("Échec de l'envoi du message.");
      }
    );
  };

  return (
    <div className={s.container}>
      <div className={s.titre}>{titre}</div>

      {formSubmitted ? (
        <div className={s.successMessage}>
          Merci pour votre message, nous vous répondrons sous peu !
          <div className={s.clapContainer}>
            <img src={clap} alt="clap" className={s.clapImage} /> {/* Display the clap image */}
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={`mt-4 ${s.form}`}>
          {/* Form fields remain unchanged */}
          <div className="row">
            <div className={`col-md-6 ${s.formGroup}`}>
              <label className={s.formLabel}>Prénom</label>
              <input
                type="text"
                className={`form-control ${s.input}`}
                name="prenom"
                value={form.prenom}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`col-md-6 ${s.formGroup}`}>
              <label className={s.formLabel}>Nom</label>
              <input
                type="text"
                className={`form-control ${s.input}`}
                name="nom"
                value={form.nom}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className={`col-md-6 ${s.formGroup}`}>
              <label className={s.formLabel}>Email</label>
              <input
                type="email"
                className={`form-control ${s.input}`}
                name="email"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={`col-md-6 ${s.formGroup}`}>
              <label className={s.formLabel}>Entreprise</label>
              <input
                type="text"
                className={`form-control ${s.input}`}
                name="entreprise"
                value={form.entreprise}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={s.formGroup}>
            <label className={s.formLabel}>Objet</label>
            <input
              type="text"
              className={`form-control ${s.input}`}
              name="objet"
              value={form.objet}
              onChange={handleChange}
              required
            />
          </div>
          <div className={s.formGroup}>
            <label className={s.formLabel}>Votre Message</label>
            <textarea
              className={`form-control ${s.input}`}
              name="message"
              value={form.message}
              onChange={handleChange}
              required
              rows="4"
            ></textarea>
          </div>
          <div className={s.formButtonContainer}>
            <button type="submit" className={`btn ${s.button}`}>
              Envoyer
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
