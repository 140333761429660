import React from 'react';
import s from "./style.module.css";
import { BoutonContact } from "components/HautDePage/BoutonContact/BoutonContact";
import { Burger } from "components/HautDePage/Burger/Burger";
import { Logo } from "components/HautDePage/Logo/Logo";
import logo from "assets/images/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  // Déterminer la classe de fond en fonction de la page active
  const getBackgroundClass = () => {
    switch (location.pathname) {
      case '/NotreCollectif':
        return s.backgroundRose;
        case '/Contact':
          return s.backgroundRose;
      case '/NosProjets':
        return s.backgroundJaune;
      case '/NosOffres':
        return s.backgroundOrange;
      default:
        return s.backgroundDefault; // Couleur par défaut si aucune des pages ci-dessus n'est active
    }
  };

  return (
    <header className={`${s.container} ${getBackgroundClass()}`}>
      <div className={s.burger}>
        <Burger />
      </div>
      <div className={s.logo2}>
      <Link to="/"><Logo image={logo} /></Link>
      </div>
      <div className={s.lien}>
        <Link to="/"><Logo image={logo} /></Link>
      </div>
      <div className={location.pathname === '/NosProjets' ? s.lienNoir : s.lien}>
        <Link to="/NotreCollectif" className={location.pathname === '/NotreCollectif' ? s.active : ''}>
          Notre collectif
        </Link>
      </div>
      <div className={location.pathname === '/NosProjets' ? s.lienNoir : s.lien}>
        <Link to="/NosProjets" className={location.pathname === '/NosProjets' ? s.active : ''}>
          Nos projets
        </Link>
      </div>
      <div className={location.pathname === '/NosProjets' ? s.lienNoir : s.lien}>
        <Link to="/NosOffres" className={location.pathname === '/NosOffres' ? s.active : ''}>
          Nos offres
        </Link>
      </div>
      <div className={s.contact}>
        <BoutonContact onClick={() => navigate("/Contact")}>Contact</BoutonContact>
      </div>
    </header>
  );
}
