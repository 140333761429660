import React from "react";
import s from "./style.module.css";
import sourire from "assets/images/sourire.svg";
import clic from "assets/images/clic.svg";
import nuage from "assets/images/nuage.svg";
import code from "assets/images/nuageRose.svg";
import detente from "assets/images/detente.svg";

export function Bento() {
  return (
    <div className={s.bentoContainer}>
      <div className={`${s.petitFormat} ${s.transition}`}>
        <div className={s.boost1}>
          <div className={s.boost1Text}>
            Booster <br /> votre <br />
            <span className={s.shrikhandBoost}> marketing. </span>
          </div>
          <div className={s.boost1Container}>
            <img className={s.sourire} src={sourire} alt="Sourire" />
          </div>
        </div>
        <div className={s.exp1}>
          <span className={s.fluo}>+ de 10 ans</span> d'expérience. 🚀
        </div>
        <div className={s.com1}>
          Créer vos
          <br /> <span className={s.shrikhand}>supports de comm'.</span>
        </div>

        <div className={s.horizontal}>
          <div className={s.site1}>
            <div>
              Créer votre <br />
              <span className={s.shrikhandSite1}> site web / mobile.</span>
            </div>
            <div className={s.clicContainer}>
              <img className={s.clic} src={clic} alt="Bonhomme clic" />
            </div>
          </div>
          <div className={s.imagine1}>
            <div className={s.imagine1Container}>
              <img className={s.nuage} src={nuage} alt="Nuage" />
            </div>
            <div className={s.textNuage1}>
              Imaginer votre <br />
              <span className={s.shrikhandNuage1}> identité de marque.</span>
            </div>
          </div>
        </div>
        <div className={s.horizontal}>
          <div className={s.ecoute1}>
            Un accompagnement dédié, <span className={s.shrikhandEcoute}>une équipe à l'écoute.</span>
          </div>
          <div className={s.solution1}>
            <div>
              Des solutions <br/> <span className={s.fluo2}>adaptées à vos besoins</span> <br/> et à votre développement.
            </div>
          </div>
        </div>
        <div className={s.solution11}>
          <div>
            Des solutions<br/>  <span className={s.fluo2}>adaptées à vos besoins</span><br/>  et à votre développement.
          </div>
        </div>
      </div>

      <div className={`${s.grandFormat} ${s.transition}`}>
        <div className={s.horizontal}>
          <div className={s.verticale2}>
            <div className={s.horizontal}>
              <div className={s.boost2}>
                <div className={s.boost2Text}>
                  Booster <br /> votre <br />
                  <span className={s.shrikhandBoost2}> marketing.</span>
                </div>
                <div className={s.boostContainer2}>
                  <img className={s.sourire2} src={sourire} alt="Sourire" />
                </div>
              </div>
              <div className={s.com2}>
                <div className={s.comText2}>
                  Créer vos
                  <span className={s.shrikhandCom2}> supports de comm'.</span>
                </div>
              </div>
            </div>
            <div className={s.exp2}>
              <div>
                <span className={s.fluo2}>+ de 10 ans</span>
                <br /> d'expérience. 🚀
              </div>
              <div className={s.detenteContainer}>
                <img className={s.detente} src={detente} alt="detente" />
              </div>
            </div>
          </div>
          <div className={s.site2}>
            <div>
              <div className={s.siteText2}>
                Créer votre
                <span className={s.shrikhandSite2}> site web / mobile.</span>
              </div>
            </div>
            <div className={s.clicContainer2}>
              <img className={s.clic2} src={clic} alt=" clic" />
            </div>
          </div>
        </div>
        <div className={s.horizontal}>
          <div className={s.creatTech2}>
            <img className={s.code2} src={code} alt="Personnage violet" />
          </div>
          <div className={s.imagine1}>
            <div className={s.imagine1Container}>
              <img className={s.nuage} src={nuage} alt="Nuage" />
            </div>
            <div className={s.textNuage1}>
              Imaginer votre <br />
              <span className={s.shrikhandNuage1}> identité de marque.</span>
            </div>
          </div>
          <div className={`${s.verticale} ${s.verticaleSpecial}`}>
            <div className={s.solution2}>
              <div className={s.solutionText2}>
                Des solutions <br/> <span className={s.fluo2}>adaptées à vos besoins</span> <br/> et à votre développement.
              </div>
            </div>
            <div className={s.ecoute2}>
              Un accompagnement dédié, <br/> <span className={s.shrikhandEcoute2}>une équipe à l'écoute.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
