import React from "react";
import s from "./style.module.css";
import flecheDroite from "assets/images/flecheDroite.png";
import flecheBlanche from "assets/images/flecheBlanche.png";
import { BoutonBanniere } from "components/HautDePage/BoutonBanniere/boutonBanniere";
import { GrosTitre } from "components/HautDePage/GrosTitre/GrosTitre";
import { Vague } from "components/HautDePage/Vague/Vague";
import VagueOrange from "assets/images/vague_orange.svg";
import { Fleche } from "components/HautDePage/Fleche/Fleche";
import { Formulaire } from "components/CorpsDePage/Formulaire/Formulaire";
import { useRef } from "react";



export function NosOffres() {
  const formulaireRef = useRef(null); // Création de la référence pour le formulaire

  const scrollToForm = () => {
    if (formulaireRef.current) {
      formulaireRef.current.scrollIntoView({ behavior: "smooth" }); // Défilement fluide jusqu'au formulaire
    }
  };

  return (
    <div className={s.container}>
      {/* HAUTE DE PAGE */}
      <div className={s.partieColore}>
        <div className={s.grosTitre}>
          <GrosTitre />
        </div>
        <div>
          <div className={s.content}>
            <div className={s.PresentationEtBouton}>
              <div className={s.presentation}>
                PixelTrust c’est avant tout une aventure entrepreneuriale entre potes. C’est le travail sérieux et passionné, avec une dose de fun !
              </div>
              <BoutonBanniere onClick={scrollToForm}>Euh, dites m'en plus...</BoutonBanniere> 
            </div>

          </div>
          <div className={s.fleche}>
            <Fleche />
          </div>
        </div>
      </div>
      <div className={s.vague}>
        <Vague image={VagueOrange} />
      </div>

      {/* CORPS */}
      <div className={s.corps}>
        <div className={s.accompagne}>
          <div className={s.titreCorps}>
            On vous <span className={s.shrikhand}>accompagne !</span>
          </div>
          <div className={s.premierePartie}>
            <div className={s.preCompetence}>
              Que vous ayez déjà ou non un site internet pour votre activité, vous vous rendrez compte que gérer et entretenir un site peut être long et fastidieux. <br /> Pas de panique, on est là pour ça !
            </div>
            <div className={s.competence}>
              <div className={s.competenceGauche}>
                <div className={s.probleme}>
                  Si vous êtes dans l’une de ses problématiques, nous pouvons vous aider :
                </div>
                <li>Vous n’avez pas de site et ne savez pas par où commencer.</li>
                <li>Vous avez un site mais il n’est jamais mis à jour par faute de temps, de moyen et/ou de connaissances.</li>
                <li>Vous avez une présence en ligne qui stagne. Vous avez un site qui ne reflète pas l’image de votre entreprise.</li>
                <li>Votre site n’est pas facile à comprendre, les internautes peinent à trouver les informations souhaitées.</li>
              </div>
              <div className={s.competenceFleche}>
                <img src={flecheDroite} alt="fleche" />
              </div>
              <div className={s.competenceDroite}>
                <div className={s.nouvelle}>
                  On a une bonne nouvelle pour vous !<br /> Tout ça, on sait faire, et en plus on aime bien !
                </div>
                <div>
                  Utilisez votre temps pour ce que vous savez le mieux faire, on s’occupe de ça !
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.colore}>
          <div className={s.titreCorps}>Nos offres</div>
          <div className={s.rectangles}>
            <div className={s.rectangle}>
              <div className={s.pack}>Pack 180°</div>
              <hr className={s.separator} />
              <div>Création et refonte de site.</div>
              <div className={s.enPlus}>
                On commence par discuter avec vous, vous écouter, beaucoup, pour bien comprendre vos besoins.
              </div>
              <div>Résultat : vous aurez un site fait juste pour vous, 100% personnalisé.</div>
            </div>
            <div className={s.rectangle}>
              <div className={s.pack}>Pack 270°</div>
              <hr className={s.separator} />
              <div>Création et refonte de site.</div>
              <div>Stratégie webmarketing.</div>
              <div className={s.enPlus}>
                En plus du site, on va travailler avec vous une stratégie pour améliorer votre présence en ligne : réseaux sociaux, newsletters, contenus, etc.
              </div>
              <div>Résultat : votre site 100% personnalisé ET une stratégie pour le faire vivre.</div>
            </div>
            <div className={s.rectangle}>
              <div className={s.pack}>Pack 360°</div>
              <hr className={s.separator} />
              <div>Création et refonte de site.</div>
              <div>Stratégie webmarketing.</div>
              <div>Création de vos supports de comm.</div>
              <div className={s.enPlus}>
                En plus du pack 270°, on vous accompagne sur les créations de supports : visuels pour les posts réseaux sociaux, dépliants, cartes de visite, vidéos...
              </div>
              <div>Résultat : votre site, votre stratégie et vos supports. Il ne vous reste plus qu’à vous en servir !</div>
            </div>
          </div>
          <div className={s.ButtonContainer}>
            <button className={s.boutonContact} onClick={scrollToForm}> 
              Contactez-nous <img src={flecheBlanche} alt="fleche blanche" />
            </button>
          </div>
        </div>
      </div>

      {/* FORMULAIRE */}
      <div ref={formulaireRef} className={s.formulaireSection}> 
        <Formulaire titre="Vous avez un projet ?" />
      </div>
    </div>
  );
}
